@import "../node_modules/bootstrap/scss/bootstrap";

.App {
  // SVG styles.
  .svg-loader {
    animation: 2s linear infinite svg-animation;
    max-width: 22px;
    margin-top: -4px;
    margin-right: 5px;

    // Circle styles.
    circle {
      animation: 1.4s ease-in-out infinite both circle-animation;
      display: block;
      fill: transparent;
      stroke: #ffffff;
      stroke-linecap: round;
      stroke-dasharray: 283;
      stroke-dashoffset: 280;
      stroke-width: 10px;
      transform-origin: 50% 50%;
    }

    // Circle animation.
    @keyframes circle-animation {
      0%,
      25% {
        stroke-dashoffset: 280;
        transform: rotate(0);
      }
      
      50%,
      75% {
        stroke-dashoffset: 75;
        transform: rotate(45deg);
      }
      
      100% {
        stroke-dashoffset: 280;
        transform: rotate(360deg);
      }
    }
  }

  // SVG animation.
  @keyframes svg-animation {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg)
    }
  }
}